<template>
  <div>
    <ol>
      <li>
        <p>Pada saat masa E-Vote Pemilihan Langsung telah dibuka, klik tombol ‘Ikuti E-Vote’ pada bagian bawah halaman utama PEMILA ILUNI UI 2022.</p>
        <img
          class="img-size-mobile"
          :src="require('@/assets/images/guides/image12.png')"
        >
      </li>
      <li>
        <p>Pilih kode negara dan input nomor handphone Rekan, kemudian pilih ‘Request OTP’ untuk mendapatkan kode OTP (one-time password). Terdapat 5 kali kesempatan untuk mengirim ulang OTP.</p>
        <img
          class="img-size-mobile"
          :src="require('@/assets/images/guides/image13.png')"
        >
      </li>
      <li>
        <p>Kode OTP akan dikirimkan melalui SMS atau WhatsApp ke nomor yang telah diinput sebelumnya. Jangan memberitahukan kode OTP yang diterima kepada siapapun.</p>
        <img
          class="img-size-mobile"
          :src="require('@/assets/images/guides/image9.png')"
        >
      </li>
      <li>
        <p>KoInput kode OTP diterima pada kolom ‘Kode OTP’ dan pilih ‘Submit OTP’.
        </p>
        <img
          class="img-size-mobile"
          :src="require('@/assets/images/guides/image11.png')"
        >
      </li>
      <li>
        <p>Pilih salah satu kandidat yang tertera di halaman ‘Pilih Kandidat’
          .
        </p>
        <img
          class="img-size-mobile"
          :src="require('@/assets/images/guides/image5.png')"
        >
        <br>
        <br>
        <img
          class="img-size-mobile"
          :src="require('@/assets/images/guides/image5.2.png')"
        >
      </li>
      <li>
        <p>Konfirmasi pilihan karena Rekan Alumni hanya memiliki satu kali kesempatan memilih
          .
        </p>
        <img
          class="img-size-mobile"
          :src="require('@/assets/images/guides/image1.png')"
        >
      </li>
      <li>
        <p>Rekan Alumni sudah berhasil melakukan E-Vote. Klik ‘Kembali ke Halaman PEMILA’ untuk menutup halaman E-Vote dan kembali ke halaman utama PEMILA ILUNI UI 2022

          .
        </p>
        <img
          class="img-size-mobile"
          :src="require('@/assets/images/guides/image10.png')"
        >
      </li>
    </ol>
  </div>
</template>

<style scoped>
 /* width: 360px; */
  .img-size-mobile {
    width: 80%
  }

  li {
    margin-bottom: 16px;
  }

  p {
    color: #1b1b1b;
  }

</style>

<script>

export default {
  components: {

  },
}
</script>
